import React from "react";
import theme from "theme";
import { Theme, Text, Section, Box, Image, Strong, Em, Icon } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { FaCheckCircle } from "react-icons/fa";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Дешевий ремонт авто
			</title>
			<meta name={"description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:title"} content={"Дешевий ремонт авто"} />
			<meta property={"og:description"} content={"Економічне рішення для вашого автомобіля"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="180px 0 184px 0" background="linear-gradient(180deg,rgba(11, 15, 59, 0.81) 0%,rgba(11, 15, 59, 0.7) 100%) 0 0 no-repeat,rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/65c9e4261d712a0023300924/images/Los-10-autos-mas-baratos-de-mantener-y-reparar-2023.webp?v=2024-02-12T09:42:46.449Z) bottom/cover repeat scroll padding-box" sm-padding="120px 0 124px 0" quarkly-title="Hero-15">
			<Text
				margin="0px 0px 30px 0px"
				text-align="center"
				color="--light"
				font="normal 400 18px/1.5 --fontFamily-sans"
				letter-spacing="1px"
			>
				До вашого надійного офісу, де якість поєднується з економією
			</Text>
			<Text
				margin="0px 0px 50px 0px"
				text-align="center"
				font="normal 700 64px/1.2 --fontFamily-sans"
				letter-spacing="1px"
				color="#ff436d"
				padding="0px 250px 0px 250px"
				lg-padding="0px 0 0px 0"
				md-font="normal 700 42px/1.2 --fontFamily-sans"
				sm-font="normal 700 36px/1.2 --fontFamily-sans"
			>
				Про нас
			</Text>
			<Text
				margin="0px 0px 0px 0px"
				color="#c8ced8"
				text-align="center"
				padding="0px 250px 0px 250px"
				font="--lead"
				lg-padding="0px 0 0px 0"
			>
				Маючи багаторічний досвід роботи на ринку, ми прагнемо пропонувати послуги з ремонту та технічного обслуговування автомобілів найвищої якості за справедливими цінами. Наш девіз «Якість, якій можна довіряти, за ціною, яку ви можете заплатити» відображає наше прагнення надавати виняткові послуги, які відповідають вашим потребам без шкоди для наших послуг.
			</Text>
		</Section>
		<Section padding="60px 0" sm-padding="40px 0" color="--dark">
			<Box margin="-16px -16px -16px -16px" display="flex" flex-wrap="wrap">
				<Box padding="16px 16px 16px 16px" width="33.333%" md-width="100%">
					<Box display="flex" flex-direction="column">
						<Text as="h2" font="--headline1" margin="0 0 8px 0">
							FAQ
						</Text>
						<Text as="p" font="--lead" margin="0" color="--darkL2">
							Поширені запитання
						</Text>
					</Box>
				</Box>
				<Box padding="16px 16px 16px 16px" width="66.66%" md-width="100%">
					<Box display="flex" flex-direction="row" flex-wrap="wrap">
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Які послуги ми пропонуємо?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Ми пропонуємо широкий спектр послуг, включаючи профілактичне технічне обслуговування, ремонт, заміну шин, діагностичні послуги, а також налаштування та оновлення.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Які години роботи?
								</Text>
							</Box>
							<Text as="p" font="--base" margin="12px 0" color="--darkL2">
								Ми працюємо з 8:00 до 18:00, а в суботу — з 8:00 до 12:00.
							</Text>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Як я можу запланувати послугу?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Ви можете записатися на обслуговування, зв'язавшись з нами по телефону, електронною поштою або особисто завітавши до нас в офіс.
								</Text>
							</Box>
						</Box>
						<Box
							display="flex"
							flex-direction="column"
							width="50%"
							padding="16px 16px 16px 16px"
							sm-width="100%"
						>
							<Box display="flex" flex-direction="column">
								<Text as="h3" font="--headline3" margin="12px 0">
									Чи надаєте ви гарантію на надані послуги?
								</Text>
								<Text as="p" font="--base" margin="12px 0" color="--darkL2">
									Але всі наші послуги супроводжуються гарантією якості. Щоб дізнатися більше про конкретну гарантію кожної послуги, зв’яжіться з нами.
								</Text>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-7">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
			>
				<Box
					overflow-x="hidden"
					transform="translateY(0px)"
					padding="0px 0px 100% 0px"
					width="100%"
					overflow-y="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					height="auto"
				>
					<Image
						src="https://uploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09:42:46.450Z"
						object-fit="cover"
						position="absolute"
						width="100%"
						bottom={0}
						display="block"
						top={0}
						left={0}
						right={0}
						min-height="100%"
						srcSet="https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65c9e4261d712a0023300924/images/coche-viejo-coche-nuevo.jpg?v=2024-02-12T09%3A42%3A46.450Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				flex-wrap="wrap"
				flex-direction="row"
				lg-align-items="center"
				padding="4px 4px 4px 4px"
				lg-order="1"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				display="flex"
				width="50%"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
			>
				<Box width="50%" padding="12px 12px 12px 13px" display="flex">
					<Box
						width="100%"
						height="auto"
						position="relative"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						overflow-x="hidden"
						overflow-y="hidden"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
					>
						<Image
							top="auto"
							left={0}
							right={0}
							bottom="0px"
							display="block"
							object-fit="cover"
							position="absolute"
							width="100%"
							min-height="100%"
							src="https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							srcSet="https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1593699199342-59b40e08f0ac?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box padding="12px 12px 12px 12px" display="flex" width="50%">
					<Box
						transition="transform 0.2s ease-in-out 0s"
						hover-transform="translateY(-10px)"
						position="relative"
						transform="translateY(0px)"
						overflow-x="hidden"
						overflow-y="hidden"
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						width="100%"
						height="auto"
					>
						<Image
							bottom="0px"
							src="https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"
							position="absolute"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							display="block"
							width="100%"
							right={0}
							srcSet="https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=500 500w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=800 800w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1080 1080w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=1600 1600w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000 2000w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2600 2600w,https://images.unsplash.com/photo-1486006920555-c77dcf18193c?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						margin="0px 0px 0px 0px"
						padding="0px 0px 100% 0px"
						transition="transform 0.2s ease-in-out 0s"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
						position="relative"
						transform="translateY(0px)"
						hover-transform="translateY(-10px)"
						width="100%"
					>
						<Image
							object-fit="cover"
							display="block"
							top="auto"
							right={0}
							bottom="0px"
							min-height="100%"
							src="https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							position="absolute"
							width="100%"
							left={0}
							srcSet="https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1586335963805-7b603f62a048?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
				<Box width="50%" padding="12px 12px 12px 12px" display="flex">
					<Box
						position="relative"
						hover-transform="translateY(-10px)"
						padding="0px 0px 100% 0px"
						transform="translateY(0px)"
						transition="transform 0.2s ease-in-out 0s"
						margin="0px 0px 0px 0px"
						width="100%"
						height="auto"
						overflow-x="hidden"
						overflow-y="hidden"
					>
						<Image
							src="https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000"
							position="absolute"
							display="block"
							top="auto"
							left={0}
							min-height="100%"
							object-fit="cover"
							width="100%"
							right={0}
							bottom="0px"
							srcSet="https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=500 500w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=800 800w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1080 1080w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=1600 1600w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2000 2000w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=2600 2600w,https://images.unsplash.com/photo-1568644305664-5c7c2f88fa65?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&h=2000&w=3200 3200w"
							sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
						/>
					</Box>
				</Box>
			</Box>
			<Box
				width="100%"
				align-items="flex-start"
				lg-align-items="center"
				sm-margin="0px 0px 20px 0px"
				display="flex"
				flex-direction="column"
				lg-width="100%"
				lg-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				lg-padding="0px 16px 16px 16px"
			>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					width="60%"
					lg-width="100%"
					md-text-align="left"
				>
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						Розташування та контакт
					</Strong>
					:
					<br />
					{" "}Ми знаходимося за адресою: Pushkinska St, 27, Zhytomyr, Zhytomyr Oblast, 10002{" "}
					<br />
					<br />
					Щоб запланувати обслуговування або зробити запит, зв'яжіться з нами за телефоном 0412240249 або електронною поштою info@vivarionstart.com.
					<br />
					<Strong
						overflow-wrap="normal"
						word-break="normal"
						white-space="normal"
						text-indent="0"
						text-overflow="clip"
						hyphens="manual"
						user-select="auto"
						pointer-events="auto"
					>
						<Em
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							<br />
							Ваше задоволення є нашим пріоритетом, і наша команда завжди готова задовольнити ваші потреби.
						</Em>
					</Strong>
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Box display="grid" grid-template-columns="repeat(3, 1fr)" grid-gap="24px 4%" md-grid-template-columns="1fr">
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Персоналізована увага
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Кожен клієнт і транспортний засіб отримують індивідуальну увагу, щоб забезпечити індивідуальні рішення, які відповідають їхнім конкретним потребам.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Доступна якість
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Ми пропонуємо високоякісні послуги за конкурентними цінами, гарантуючи, що вам не доведеться вибирати між доглядом за своїм автомобілем і обслуговуванням його обладнання.
					</Text>
				</Box>
				<Box justify-content="flex-start" flex-direction="column" display="flex">
					<Icon
						category="fa"
						icon={FaCheckCircle}
						margin="0px 0px 16px 0px"
						color="--dark"
						size="48px"
						padding="6px 6px 6px 6px"
					/>
					<Text margin="0px 0px 18px 0px" color="--darkL2" font="--headline3" lg-text-align="left">
						Швидкість і ефективність
					</Text>
					<Text
						margin="0px 0px 0px 0px"
						color="--greyD3"
						font="--base"
						lg-text-align="left"
						flex="1 0 auto"
					>
						Завдяки новітнім технологіям і досвідченій команді ми гарантуємо швидкі та ефективні послуги, мінімізуючи час простою вашого транспортного засобу та безпечно повертаючи вашу силу в дорогу.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Header>
			<Override slot="link3">
				0412240249
			</Override>
			<Override slot="text3" />
			<Override slot="link4">
				info@vivarionstart.com
			</Override>
		</Components.Header>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65bb673d867c86001f1b379d"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});